<template>
  <div></div>
</template>
<script>
import desktopUiFixes from '@utils/mixins/desktop-no-common-parts-ui-fixes.js'
import { legitRedirectUrl } from '@utils/common-methods/common'

export default {
  mixins: [desktopUiFixes],
  mounted() {
    this.proceedWithAuth0Link()
  },
  methods: {
    proceedWithAuth0Link() {
      window.location.href = legitRedirectUrl(this.$route.query.ticket)
    },
  },
}
</script>
